import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { StaticImage } from 'gatsby-plugin-image'
import { FixedContainer } from '../styled/containers'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`
const StyledContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: flex-start;
`

const AboutDiagram = () => {
  return (
    <Container>
      <StyledContainer>
        <a href={"https://d34rq7yialevbd.cloudfront.net/uploads/f4f7ba4e-8761-437b-ba78-7faff52dae7f"} target={'_blank'}>
        <img
          src="https://d34rq7yialevbd.cloudfront.net/uploads/f4f7ba4e-8761-437b-ba78-7faff52dae7f"
          alt="workspace"
          layout="constrained"
          className="w-100"
          placeholder="tracedSVG"
        />
        </a>
      </StyledContainer>
    </Container>
  )
}

export default AboutDiagram
